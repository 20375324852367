import api from "../axios";

const request = async (method, endpoint, data = {}, params = {}, headers = {}) => {
  try {
    return await api({
      method,
      url: endpoint,
      data: method !== "get" ? data : undefined,
      params,
      headers: {
        ...api.defaults.headers.common,
        ...headers,
      },
    });
  } catch (error) {
    console.error(`Error with ${method.toUpperCase()} ${endpoint}:`, error);
    throw error;
  }
};

const apiClient = {
  get: (endpoint, params = {}, headers = {}) => request("get", endpoint, {}, params, headers),
  post: (endpoint, data = {}, params = {}, headers = {}) =>
    request("post", endpoint, data, params, headers),
  put: (endpoint, data = {}, params = {}, headers = {}) =>
    request("put", endpoint, data, params, headers),
  patch: (endpoint, data = {}, params = {}, headers = {}) =>
    request("patch", endpoint, data, params, headers),
  delete: (endpoint, data = {}, params = {}, headers = {}) =>
    request("delete", endpoint, data, params, headers),
};

export default apiClient;
