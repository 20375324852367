import MDTypography from "components/MDTypography";

export const Name = ({ name = "", varient = "caption", fontWeight = "medium" }) => {
  return (
    <>
      {name && (
        <MDTypography variant={varient} fontWeight={fontWeight}>
          {name}
        </MDTypography>
      )}
    </>
  );
};

export const Price = ({ price, varient = "caption", fontWeight = "medium" }) => {
  return (
    <>
      {price && (
        <MDTypography variant={varient} fontWeight={fontWeight}>
          ${price}
        </MDTypography>
      )}
    </>
  );
};

export const handleSorting = (
  columnId,
  sortDirection,
  currentSortBy,
  currentSortOrder,
  setSortBy,
  setSortOrder,
  columnNameMapping = {}
) => {
  const newSortBy = columnNameMapping[columnId] || columnId;
  const newSortOrder = sortDirection === "desc" ? "DESC" : "ASC";

  if (newSortBy !== currentSortBy || newSortOrder !== currentSortOrder) {
    setSortBy(newSortBy);
    setSortOrder(newSortOrder);
  }
};
