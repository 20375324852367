import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import api from "../../axios";
import { ActivityFeedStatus } from "helper";
import NotificationAlerts from "./NotificationAlerts";

const Notifications = ({ isDrawer = false, onCloseNotification = null }) => {
  const [notifications, setNotifications] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    fetchActivityFeed();
  }, []);

  const fetchActivityFeed = async () => {
    try {
      const { data } = await api.get("activity-feed");
      setNotifications(data);
    } catch (error) {
      toast.error(error?.response?.data?.message || error?.message);
    }
  };

  const handleAlertClick = () => {
    if (isDrawer) {
      onCloseNotification();
    }
    navigate("/sales");
  };

  const handleDismiss = async (e, notification) => {
    if (e) {
      e.stopPropagation();
    }

    try {
      await api.patch(`activity-feed/${notification.id}`, {
        status: ActivityFeedStatus.Removed,
      });

      fetchActivityFeed();
    } catch (error) {
      toast.error(error?.response?.data?.message || "Failed to dismiss notification.");
    }
  };

  const renderContent = (
    <MDBox p={2}>
      {isDrawer ? "" : <MDTypography variant="h5">Activity Feed</MDTypography>}
      <NotificationAlerts
        data={notifications}
        handleAlertClick={handleAlertClick}
        handleDismiss={handleDismiss}
        isDrawer={isDrawer}
        onCloseNotification={onCloseNotification}
      />
    </MDBox>
  );

  return (
    <MDBox mb={3}>
      <Grid container spacing={3} justifyContent="center">
        <Grid item xs={12} lg={12}>
          {isDrawer ? renderContent : <Card>{renderContent}</Card>}
        </Grid>
      </Grid>
    </MDBox>
  );
};

export default Notifications;
