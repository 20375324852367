import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Icon from "@mui/material/Icon";
import Drawer from "@mui/material/Drawer"; // Import Drawer for modal behavior
import Box from "@mui/material/Box";

import Breadcrumbs from "muiComponents/Breadcrumbs";
import Notifications from "layouts/notifications";
import { navbar, navbarContainer, navbarRow } from "muiComponents/Navbars/DashboardNavbar/styles";

import { useMaterialUIController, setTransparentNavbar, setMiniSidenav } from "context";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import { useMediaQuery } from "@mui/material";

const DashboardNavbar = ({ absolute, light, isMini }) => {
  const [navbarType, setNavbarType] = useState();
  const [controller, dispatch] = useMaterialUIController();
  const isSmallScreen = useMediaQuery("(max-width:600px)");
  const { miniSidenav, fixedNavbar, transparentNavbar, darkMode } = controller;
  const [notificationsOpen, setNotificationsOpen] = useState(false);

  const route = useLocation().pathname.split("/").slice(1);

  useEffect(() => {
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }
    function handleTransparentNavbar() {
      setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar);
    }

    handleTransparentNavbar();
  }, [dispatch]);

  useEffect(() => {
    if (!isSmallScreen) {
      setNotificationsOpen(false);
    }
  }, [isSmallScreen]);

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);

  const handleNotificationsOpen = () => setNotificationsOpen(true);

  const handleNotificationsClose = () => setNotificationsOpen(false);

  const iconsStyle = ({ palette: { dark, white, text }, functions: { rgba } }) => ({
    color: () => {
      let colorValue = light || darkMode ? white.main : dark.main;

      if (transparentNavbar && !light) {
        colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
      }

      return colorValue;
    },
  });

  return (
    <>
      <AppBar
        position={absolute ? "absolute" : navbarType}
        color="inherit"
        sx={(theme) => navbar(theme, { transparentNavbar, absolute, light, darkMode })}
      >
        <Toolbar sx={(theme) => navbarContainer(theme)}>
          <Breadcrumbs icon="home" title={route[route.length - 1]} route={route} light={light} />
          {isSmallScreen ? (
            <MDBox sx={(theme) => navbarRow(theme, { isMini })}>
              <MDBox color={light ? "white" : "inherit"}>
                <IconButton onClick={handleMiniSidenav}>
                  <Icon sx={iconsStyle}>{miniSidenav ? "menu_open" : "menu"}</Icon>
                </IconButton>
                <IconButton onClick={handleNotificationsOpen}>
                  <Icon sx={iconsStyle}>notifications</Icon>
                </IconButton>
              </MDBox>
            </MDBox>
          ) : (
            ""
          )}
        </Toolbar>
      </AppBar>

      {isSmallScreen && (
        <Drawer
          anchor="right"
          open={notificationsOpen}
          onClose={handleNotificationsClose}
          PaperProps={{
            sx: {
              width: "94%",
              padding: "16px",
              backgroundColor: "white",
              boxShadow: "-4px 0 12px rgba(0, 0, 0, 0.1)",
              height: "100%",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "16px",
            }}
          >
            <MDTypography varient="h5">Activity Feed</MDTypography>
            <IconButton onClick={handleNotificationsClose}>
              <Icon>close</Icon>
            </IconButton>
          </Box>
          <Notifications isDrawer={true} onCloseNotification={handleNotificationsClose} />
        </Drawer>
      )}
    </>
  );
};

export default DashboardNavbar;
